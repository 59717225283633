import { AnyObject } from 'yup';

import { toast } from 'lib/toast';

import { GraphApiErr } from './GraphApiErr';
import { flattenObject, logException } from './logHandler';
import { WebAppErr } from './WebAppErr';

interface ArgType {
  tagName?: string;
  showAlert?: boolean;
  fallbackMsg?: string;
  additionalArgs?: AnyObject;
}

/**
 * A generic error handler for the application. This function is called from anywhere.
 * The function checks the type of error and calls the appropriate handler.
 * Basic ideas of this function are:
 * 1. Report the error to Sentry with more richful context
 * 2. Show the generic toast message to the user (configurable)
 *
 * @param {error} err
 * @param {object} argument { showAlert, feature, additionalInfo, fallbackMsg } see below:
 *  - @param {boolean} argument.showAlert - Show the alert to the user or not
 *  - @param {string} argument.feature - Feature name where the error occurred
 *  - @param {object} argument.additionalInfo - Additional information to be sent to Sentry. (Redux state or any payload)
 *  - @param {string} argument.fallbackMsg - Fallback message to show to the user
 */
export const handleErrors = (err: Error, arg?: ArgType) => {
  const {
    showAlert = true,
    fallbackMsg = '',
    additionalArgs = {},
    tagName = '',
  } = arg || {
    showAlert: true,
    fallbackMsg: '',
    additionalArgs: {},
    tagName: '',
  };

  /* eslint-disable */
  const tempMsg = { msg: `context: ${err.message}, additionalMsg: ${fallbackMsg} ` };

  if (err instanceof GraphApiErr && err.isGraphApiErr) {
    if (showAlert) {
      toast({
        title: fallbackMsg ?? 'Graphql API Error',
      });
    }
    const finalArg = { ...err.args, ...additionalArgs };

    logException(err, tempMsg, flattenObject(finalArg), tagName);
    return;
  }

  if (err instanceof WebAppErr && err.isWebAppErr) {
    if (showAlert) {
      toast({
        title: 'Web App Error',
      });
    }
    const finalArg = { ...err.args, ...additionalArgs };

    logException(err, tempMsg, flattenObject(finalArg), tagName);
    return;
  }

  if (showAlert) {
    toast({
      title: err.message,
    });
  }

  logException(err, tempMsg, flattenObject(additionalArgs), tagName);
};
